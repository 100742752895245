@use '../abstract/variables'as *;

#menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    gap: 0px;
    padding: 100px;
    padding-bottom: 200px;

    @media screen and (max-width: 1000px) {
        padding: 80px 20px;
    }
}

.menu__section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 40px 0;

    &_title {
        font-size: 30px;
        text-transform: uppercase;
        font-weight: bold;
        border-bottom: 1px solid $orange;
        padding: 0 20px;
        padding-bottom: 5px;

        @media screen and (max-width: 1000px) {
            padding: 0;
        }
    }

    &_desc {
        padding: 0 20px;
        font-size: 14px;

        @media screen and (max-width: 1000px) {
            padding: 0;
        }
    }
}

.menu__item {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &_top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_desc {
        font-size: 14px;
    }

    &_name {
        font-size: 22px;
        font-weight: bold;
    }

    @media screen and (max-width: 1000px) {
        gap: 8px;
    }
}

.menu__items {
    position: relative;
    padding: 0 20px;
    margin: 30px 0;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 100px;
    row-gap: 50px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
        row-gap: 40px;
        margin: 15px 0;
        padding: 0;
    }
}

.mid-line {
    position: absolute;
    width: 1px;
    background-color: $orange;
    height: calc(100% + 50px);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    @media screen and (max-width: 1000px) {
        display: none;
    }
}

.menu__control {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;

    @media screen and (max-width: 1000px) {
        padding-bottom: 20px;
    }

    &_menus {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 50px;
        text-transform: uppercase;

        @media screen and (max-width: 1000px) {
            justify-content: space-evenly;
            gap: 10px;
        }

        span {
            cursor: pointer;
            color: #fff;
            font-size: 22px;
            transition: color 0.5s;

            &:hover {
                color: $orange;
            }
        }

        .menu-selected {
            position: relative;
            color: $orange;
            font-weight: bold;

            &:after {
                position: absolute;
                content: "";
                float: left;
                background: $orange;
                width: 100%;
                height: 2px;
                border-radius: 3px;
                left: 0;
                bottom: -5px;
            }
        }
    }

    &_sections {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 20px;
        box-sizing: border-box;
        margin-bottom: 20px;

        @media screen and (max-width: 1000px) {
            display: none;
        }
    }

    &_section {
        width: fit-content;
        text-transform: uppercase;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
        transition: color 0.5s;

        &:hover {
            color: $orange;
        }
    }
}