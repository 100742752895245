@use '../abstract/variables'as *;

#footer {
    height: 60px;
    background-color: #000;
    color: $orange;
    width: 100%;
    padding: 5px 80px;
    box-sizing: border-box;
    font-size: 24px;
    box-shadow:
        rgba(0, 0, 0, 0.2) 0px 3px 0px inset,
        rgba(0, 0, 0, 0.3) 0px -7px 13px -3px,
        rgba(0, 0, 0, 0.4) 0px -2px 4px;
    display: flex;
    justify-content: center;
    align-items: center;

    &-left {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 40px;
        margin-bottom: -5px;

        a:hover {
            color: #935f2c;
        }

        @media screen and (max-width: 900px) {
            margin-bottom: 0;
        }
    }

    &-right {
        font-size: 12px;
        text-transform: uppercase;
        user-select: none;

        @media screen and (max-width: 900px) {
            font-size: 12px;
        }
    }
}