@use '../abstract/variables'as *;

.swiper-pagination-bullet-active {
    background-color: $orange !important;
}

.swiper-img {
    height: 100%;
    width: auto;
    overflow: hidden;
    object-fit: cover;
    user-select: none;
}

.swiper {
    width: 100%;
    height: auto;
    padding: 50px;
    box-sizing: border-box;
    padding-top: 0;
}

.swiper-slide {
    background-position: center;
    background-size: cover;
    width: 100%;
}

.swiper-slide img {
    display: block;
    width: 100%;
}

.swiper-button-prev {
    color: $orange !important;
}

.swiper-button-next {
    color: $orange !important;
}