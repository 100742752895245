@use '../abstract/variables' as *;

.price-tag {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 2px;

    &__dollars {
        font-size: 20px;
    }

    &__cents {
        font-size: 10px;
    }
}