@use 'abstract/variables'as *;
// views
@use 'views/home';
@use 'views/menu';
@use 'views/about';
// components
@use 'components/navbar';
@use 'components/footer';
@use 'components/coming_soon';
@use 'components/image_stack';
@use 'components/price_tag';

body {
    margin: 0;
    font-family: 'Comfortaa', cursive;
    background-color: #000;
}

.no-scroll {
    height: 100% !important;
    overflow: hidden !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.btn {
    font-family: inherit;
    border: 1px;
}

/* CSS */
.btn {
    border: 0;
    background-color: $orange;
    color: #fff;
    transition: all 0.3s;
    cursor: pointer;
    font-size: 14px;
    font-family: inherit;
    text-transform: uppercase;
    box-sizing: border-box;
    width: fit-content;
    white-space: nowrap;
    padding: 15px 60px;

    &:hover {
        padding: 15px 60px;
        font-size: 18px;
    }

    @media screen and (max-width: 800px) {
        padding: 15px 30px;

        &:hover {
            padding: 15px 30px;
            font-size: 18px;
        }
    }

    &.btn-black {
        background-color: #000;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

.content {
    padding-top: 80px;

    @media screen and (max-width: 820px) {
        padding-top: 70px;
    }
}

.orange-text {
    color: $orange
}