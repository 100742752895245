@use '../abstract/variables' as *;

#hero {
    width: 100%;
    z-index: 1;
    position: relative;

    #hero-text {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        display: flex;
        flex-direction: column;
        gap: 30px;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 800px;
        padding: 0 50px;
        box-sizing: border-box;
        z-index: 1;

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 40px;

            @media screen and (max-width: 1000px) {
                font-size: 14px;
            }

            svg {
                font-size: 25px;
                margin-left: -20px;
            }
        }


        @media screen and (max-width: 1000px) {
            padding: 0 20px;

            #hero-logo {
                width: 100%;
            }
        }
    }

    #hero-image {
        overflow: hidden;
        position: relative;

        @media screen and (max-width: 1000px) {
            height: 100vh;
        }

        img {
            width: 100%;
            z-index: -1;

            @media screen and (max-width: 1000px) {
                height: 100%;
                width: auto;

                position: absolute;
            }

        }
    }
}

#featured {
    width: 100%;
    position: relative;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 50% 50%;
    overflow: hidden;
    padding: 80px 0;

    @media screen and (max-width: 800px) {
        grid-template-columns: 100%;
        padding: 80px 0;
    }

    &-img {
        padding: 0 10%;

        @media screen and (max-width: 1200px) {
            padding: 10px;
        }
    }

    &-bg {
        position: absolute;
        z-index: -1;
        width: 100%;
        max-height: auto;
        overflow: hidden;
        object-fit: cover;

        img {
            width: 100%;
            height: auto;
        }
    }

    &-text {
        width: 100%;
        padding: 20px;

        &-container {
            color: $orange;
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            gap: 30px;
            padding-right: 40px;

            @media screen and (max-width: 1000px) {
                font-size: 50px;
                padding: 0 20px;
            }
        }

        &-header {
            display: flex;
            flex-direction: column;
            gap: 5px;

            span {
                font-size: 70px;

                @media screen and (max-width: 1200px) {
                    font-size: 50px;

                }

                @media screen and (max-width: 800px) {
                    text-align: center;
                    font-size: 40px;
                }
            }
        }

        &-desc {
            display: block;
            font-size: 22px;
            color: #fff;
            overflow: hidden;
            word-wrap: normal;
            word-wrap: break-word;
            overflow-wrap: break-word;
            width: 100%;

            @media screen and (max-width: 800px) {
                font-size: 20px;
                text-align: center;
            }

        }
    }
}

#hours {
    width: 100%;
    height: 600px;
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    overflow: hidden;

    @media screen and (max-width: 800px) {
        height: 400px;
    }

    &-text {
        text-align: center;
        width: 100%;
        font-size: 50px;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        white-space: pre;

        @media screen and (max-width: 800px) {
            font-size: 40px;
        }

        @media screen and (max-width: 600px) {
            font-size: 25px;
        }
    }

    &-img {
        position: absolute;
        z-index: -1;
        height: 100%;
        max-height: auto;
        overflow: hidden;
        width: 100%;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}


#more {
    position: relative;
    height: 600px;
    width: 100%;
    margin-bottom: 90px;

    @media screen and (max-width: 800px) {
        height: 400px;
        margin-bottom: 30px;
    }

    &-bg {
        position: sticky;
        z-index: 1;
        height: 100%;
        left: 0;
        right: 0;
        top: 0;
        overflow: hidden;

        img {
            width: auto;
            height: 100%;
        }
    }
}

.caro {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    height: 100%;
    width: 100%;
    padding: 120px 20px 30px 20px;
    box-sizing: border-box;
    z-index: 9;
    overflow-x: scroll;
    overflow-y: hidden;
    --sb-track-color: #1b1b1b;
    --sb-thumb-color: #d38e4a;
    --sb-size: 14px;

    @media screen and (max-width: 800px) {
        gap: 12px;
        padding: 60px 12px 15px 12px;
    }
}

.caro::-webkit-scrollbar {
    width: var(--sb-size)
}

.caro::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 0px;
}

.caro::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 10px;

}

@supports not selector(::-webkit-scrollbar) {
    body {
        scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
    }
}

@supports not selector(::-webkit-scrollbar) {
    body {
        scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
    }
}

.caro-img {
    height: 100%;
    width: auto;
    user-select: none;
    pointer-events: none;
}

#cta {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 80px 0;
    margin-top: 0;
    box-sizing: border-box;

    @media screen and (max-width: 800px) {
        padding: 10px;
    }
}

.hero-btn-wrap {
    width: 100%;
    display: flex;
    align-items: center;
}