@use '../abstract/variables' as *;

#coming-soon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    color: #fff;
    height: calc(100vh - 80px - 45px);

    @media screen and (max-width: 820px) {
        height: calc(100vh - 70px - 65px);
    }

    &-header {
        font-family: 'Allison', cursive;
        font-size: 100pt;

        @media screen and (max-width: 820px) {
            font-size: 70pt;
        }
    }

    &-text {
        font-size: 20pt;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 40px;

        @media screen and (max-width: 820px) {
            font-size: 15pt;
            margin-bottom: 150px;
            padding: 0 20px;
        }

        a {
            color: $orange;
            text-decoration: underline;
            text-decoration-thickness: 3px;
            text-underline-offset: 4px;
        }
    }
}