@use '../abstract/variables'as *;

.mobile-nav-wrap {
    position: fixed;
    display: block;
    z-index: 97;
    width: 100%;
    pointer-events: none;
}

#mobile-pop-menu {
    pointer-events: all;
    top: 50px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    background-color: #000000;
    height: 100vh;
    z-index: 98;

    &.closed {
        opacity: 0;
        pointer-events: none;
    }

    @media screen and (min-width: 800px) {
        display: none;
    }

    .pop-menu-links {
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 25px;
        font-size: 25pt;
        padding: 0px 10px;
        padding-bottom: 70px;
        height: calc(100% - 200px);
        white-space: nowrap;
        font-weight: 400;

        span {
            cursor: pointer;
        }

        span:hover {
            color: #935f2c;
        }
    }
}

.selected {
    position: relative;
    color: $orange;
    font-weight: bold;

    &:after {
        position: absolute;
        content: "";
        float: left;
        background: $orange;
        width: 100%;
        height: 2px;
        border-radius: 3px;
        left: 0;
        bottom: -5px;
    }
}

.slide-in {
    left: 40px;
}

.slide-out {
    left: 100%;
}

#mobile-nav {
    pointer-events: all;
    z-index: 99;
    height: 50px;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 1);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    padding: 0 20px;
    box-sizing: border-box;
    align-items: center;
    color: $orange;

    @media screen and (min-width: 1000px) {
        display: none;
    }

    #m-logo-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 70px;

        #m-logo {
            cursor: pointer;
            width: 100%;
            height: auto;
        }
    }

    .logo-text {
        text-transform: uppercase;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 5px;
        cursor: pointer;
    }

    #mobile-hamburger {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        i {
            cursor: pointer;
        }
    }
}

#nav {
    position: fixed;
    z-index: 99;
    height: 60px;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 1);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    padding: 5px 80px;
    box-sizing: border-box;
    transition: 0.5s;

    @media screen and (max-width: 800px) {
        display: none;
    }

    .nav-links {
        color: $orange;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;
        width: 100%;
        gap: 15%;
        user-select: none;
        white-space: nowrap;
        text-transform: uppercase;

        &#nav-left {
            justify-content: flex-start;
        }

        &#nav-right {
            justify-content: flex-end;
        }

        span {
            cursor: pointer;
            transition: all 0.1s ease-in-out;
        }

        span:hover {
            color: #935f2c;
        }
    }
}

#logo-container {
    display: flex;
    justify-content: center;
    align-items: center;

    #logo {
        height: 45px;
        cursor: pointer;

        @media screen and (max-width: 800px) {
            height: 50px;
        }
    }
}