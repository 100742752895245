@use '../abstract/variables' as *;

.about {
    padding: 80px;

    @media screen and (max-width: 800px) {
        padding: 60px 25px;
    }
}

.about__banner {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 584px;

    @media screen and (max-width: 800px) {
        width: 100%;
        height: auto;
    }

    &_text {
        color: $orange;
        position: absolute;
        font-size: 60px;
        margin: auto;
        z-index: 9;

        @media screen and (max-width: 800px) {
            font-size: 36px;
        }
    }

    &_img-color {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;

            @media screen and (max-width: 800px) {
                width: 120%;
                height: auto;
            }
        }
    }

    &_img {
        height: 100%;
        width: 100%;

        img {
            height: 100%;
            width: 100%;
            opacity: 0.4;
            object-fit: cover;

            @media screen and (max-width: 800px) {
                width: 120%;
                height: auto;
            }
        }
    }
}

.about__letter {
    display: flex;
    flex-direction: column;
    gap: 30px;
    color: #fff;
    font-size: 20px;
    padding: 40px 0;
    line-height: 1.3;

    @media screen and (max-width: 800px) {
        font-size: 16px;
        padding: 20px 0;
    }

    p {
        padding: 0;
        margin: 0;
    }

    &_signature {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;

    }
}

.about__footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    @media screen and (max-width: 800px) {
        gap: 23px;
        flex-direction: column-reverse;
    }

    &_row {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
        padding-bottom: -2px;

        @media screen and (max-width: 800px) {
            grid-template-columns: 1fr;
            gap: 25px;
        }
    }

    &_img {
        width: 100%;
        height: auto;

        img {
            width: 100%;
            height: auto;
        }
    }
}